const BASE_URL = `${process.env.REACT_APP_BASE_URL}v1/api`;
// const BASE_URL = "https://a725-2401-4900-1c94-6080-54d6-6459-7c3a-ddee.ngrok-free.app";
const Endpoints = {
  // auth
  LOGIN: { url: `${BASE_URL}/auth/signin`, method: "POST" },
  SIGN_UP: {
    url: `${BASE_URL}/auth/signup`,
    method: "POST",
  },
  CHANGE_PASSWORD: {
    url: `${BASE_URL}/auth/changePassword`,
    method: "POST",
  },
  //data fetch
  GET_FORMS: {
    url: `${BASE_URL}/typeform/get-responses/`,
    method: "GET",
  },
  FETCH_PDF: {
    url: `${BASE_URL}/typeform/get-documents`,
    method: "POST",
  },
  GET_USERDATA: {
    url: (userId) => `${BASE_URL}/userData/${userId}`,
    method: "GET",
  },
  GET_PROFILES: {
    url: (userName) => `${BASE_URL}/userData/get-profiles/${userName}`,
    method: "GET",
  },
  GET_PROFILE: {
    url: (id) => `${BASE_URL}/userData/get-profile/${id}`,
    method: "GET",
  },
  ADD_PROFILE: {
    url: `${BASE_URL}/userData/add-profile`,
    method: "POST",
  },
  UPDATE_PROFILE: {
    url: (id) => `${BASE_URL}/userData/update-profile/${id}`,
    method: "POST",
  },
  DELETE_PROFILE: {
    url: (id) => `${BASE_URL}/userData/delete-profile/${id}`,
    method: "DELETE",
  },
  ADD_ADDRESS: {
    url: `${BASE_URL}/userData/add-address`,
    method: "POST",
  },
  UPDATE_ADDRESS: {
    url: (userName, id) =>
      `${BASE_URL}/userData/update-address/${id}?userName=${userName}`,
    method: "POST",
  },

  //send data
  CREATE_DOC_TEMPLATE_TYPE_FORM: {
    url: `${BASE_URL}/document/createTemplateTypeForm`,
    method: "POST",
  },
  CREATE_DOC_TEMPLATE: {
    url: `${BASE_URL}/document/createTemplate`,
    method: "POST",
  },
  GET_DOCUMENT_TEMPLETS: {
    url: `${BASE_URL}/document/templets`,
    method: "POST",
  },
  GET_DOCUMENT_TEMPLETS_BY_SEARCH_KEY: {
    url: `${BASE_URL}/document/templetsBySearchKey`,
    method: "POST",
  },
  SEND_DOCUMENT_TO_PARTY_TWO: {
    url: `${BASE_URL}/document/sendToPartyTwo`,
    method: "POST",
  },
  GET_SENT_DOCUMENTS: {
    url: `${BASE_URL}/document/getSentDocuments`,
    method: "POST",
  },
  GET_SENT_DOCUMENTS_BY_SEARCH_KEY: {
    url: `${BASE_URL}/document/getSentDocumentsBySearchKey`,
    method: "POST",
  },
  GET_ALL_DOCUMENT_BY_SEARCH_KEY: {
    url: `${BASE_URL}/document/getAllDocumentsBySearchKey`,
    method: "POST",
  },
  VERIFY_PARTY_TWO_TOKEN: {
    url: `${BASE_URL}/document/verifyPartyTwoToken`,
    method: "POST",
  },
  PARTY_TWO_DATA_UPDATE: {
    url: `${BASE_URL}/document/partyTwoDataUpdate`,
    method: "POST",
  },
  RESEND_LINK_PARTY_TWO: {
    url: `${BASE_URL}/document/resendLinkPartyTwo`,
    method: "POST",
  },
  GET_PDF_URL: {
    url: `${BASE_URL}/document/getPdfUrl`,
    method: "POST",
  },
  GET_PDF_BUFFER: {
    url: `${BASE_URL}/document/getPdfBuffer`,
    method: "POST",
  },
  SUCCESS_ESIGN: {
    url: `${BASE_URL}/document/successEsign`,
    method: "POST",
  },
  SUCCESS_ESIGN_PARTY_TWO: {
    url: `${BASE_URL}/document/successEsignPartyTwo`,
    method: "POST",
  },
  GENRATE_PARTY_ONE_SIGN_LINK: {
    url: `${BASE_URL}/document/generatePartyOneEsignLink`,
    method: "POST",
  },
  // temp
  DOC_TEMP_TEST: {
    url: `${BASE_URL}/document/docTempTest`,
    method: "POST",
  },
  GET_PDF_BUFFER_TEMP: {
    url: `${BASE_URL}/document/getTempPdfBuffer`,
    method: "POST",
  },
};

export default Endpoints;

import { lazy } from "react";

const Home = lazy(() => import("../screens/Home"));
const Manage = lazy(() => import("../screens/Manage"));
const Execute = lazy(() => import("../screens/Execute"));
const PrivacyPolicy = lazy(() => import("../screens/PrivacyPolicy"));
const Billing = lazy(() => import("../screens/Billing"));
const Profile = lazy(() => import("../screens/Profile"));
const CreateProfilePage = lazy(() => import("../screens/CreateProfilePage"));
const SelectProfilePage = lazy(() => import("../screens/SelectProfilePage"));
const CreateProfileForm = lazy(() => import("../screens/CreateProfileForm"));
const Faq = lazy(() => import("../screens/Faq"));
const TempScreen = lazy(() => import("../screens/TempScreen"));
const SearchPage = lazy(() => import("../screens/SearchPage"));

const routes = [
  {
    path: "/",
    key: "1",
    name: "",
    element: <Home />,
    permission: ["Admin"],
  },
  {
    path: "/manage",
    key: "2",
    name: "manage",
    element: <Manage />,
    permission: ["Admin"],
  },
  {
    path: "/execute",
    key: "3",
    name: "execute",
    element: <Execute />,
    permission: ["Admin"],
  },
  {
    path: "/policy",
    key: "4",
    name: "policy",
    element: <PrivacyPolicy />,
    permission: ["Admin"],
  },
  {
    path: "/billing",
    key: "5",
    name: "billing",
    element: <Billing />,
    permission: ["Admin"],
  },
  {
    path: "/profile",
    key: "6",
    name: "profile",
    element: <Profile />,
    permission: ["Admin"],
  },
  {
    path: "/createProfile",
    key: "9",
    name: "createProfile",
    element: <CreateProfilePage />,
    permission: ["Admin"],
  },
  {
    path: "/selectProfile",
    key: "10",
    name: "selectProfile",
    element: <SelectProfilePage />,
    permission: ["Admin"],
  },
  {
    path: "/createProfileForm/:profileType",
    key: "11",
    name: "createProfileForm",
    element: <CreateProfileForm />,
    permission: ["Admin"],
  },
  {
    path: "/faq",
    key: "12",
    name: "faq",
    element: <Faq />,
    permission: ["Admin"],
  },
  {
    path: "/temp",
    key: "13",
    name: "temp",
    element: <TempScreen />,
    permission: ["Admin"],
  },
  {
    path: "/search/:searchkey",
    key: "14",
    name: "search",
    element: <SearchPage />,
    permission: ["Admin"],
  },
];
export default routes;
